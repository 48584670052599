// pages/404.js
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    // 在页面加载后立即重定向到首页
    router.replace('/');
  }, [router]);

  return null; // 页面显示空内容，因为会立即重定向
}
